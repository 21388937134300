import React, { useState, useEffect } from "react";
import "../assets/commingSoon.css"

function Section() {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {

    
    const now = new Date();
    const currentYear = now.getFullYear();
    let targetDate = new Date(`September 15, ${currentYear}`);

    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="section-container">
      <h1 className="heading">
        <span>WE'RE</span> <br /> COMING <br /> SOON
      </h1>
      <p className="paragraph-text">
        Hello fellow shoppers! We're currently building our new FelnaTech Website.
      </p>
      <div className="countdown">
        <div className="countdown-item">
          <span className="countdown-number">{timeLeft.days}</span> Days
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeLeft.hours}</span> Hours
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeLeft.minutes}</span> Minutes
        </div>
        <div className="countdown-item">
          <span className="countdown-number">{timeLeft.seconds}</span> Seconds
        </div>
      </div>
    </div>
  );
}

export default Section;
