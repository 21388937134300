import React from "react";
// import { ReactComponent as Logo } from "../assets/images/logo.svg";
import BGImage from "../assets/images/igl.jpeg";
import logo from '../assets/images/logo.png'

function Header() {
  return (
    <div>
      <img src={logo} alt="logo" style={{marginTop:"15px"}}/>
      <img src={BGImage} className="header-img" alt="Hero" />
    </div>
  );
}

export default Header;
