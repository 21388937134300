import React from "react";
import "./App.css";
import Header from "./components/Header";
import Section from "./components/Section";
import BGImage from "../src/assets/images/it-product.jpg";

function App() {
  const screenSize = window.matchMedia("(min-width: 1024px)");
  let desktopView = screenSize.matches;

  return (
    <div className="app">
      <div className="lg-container">
        <Header />
        <Section />
      </div>
      <div
        style={{
          display: desktopView ? "block" : "none",
          textAlign: "center",
          marginTop: "20px"
        }}
      >
        <img
          src={BGImage}
          alt="Hero"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "1200px", // Adjust maxWidth as needed
            margin: "0 auto"
          }}
        />
      </div>
    </div>
  );
}

export default App;
